import { CardActions, CardContent } from "@mui/material"
import { FC } from "react"
import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import { useLocale } from "src/states/locale"
import { getCartProductAmount, useCart } from "src/states/shop/carts"

import styles from "./ShopCartOverviewSummary.module.scss"

const bem = create(styles, "ShopCartOverviewSummary")

export type ShopCartOverviewSummaryProps = {}

export const ShopCartOverviewSummary: FC<ShopCartOverviewSummaryProps> = () => {
	const locale = useLocale()
	const translation = useTranslation()
	const m = translation.messages.pages.shop.cart.overview.summary
	const cart = useCart()
	return (
		<Card>
			<CardContent>
				<div className={bem()}>
					<div className={bem('amount')}>
						<div>{m.amount}</div>
						<div className={bem('amount__result')}>
							{cart.products.reduce((sum, product) => sum + getCartProductAmount(product), 0)}
						</div>
					</div>
					<div className={bem('sum')}>
						<div>{m.sum}</div>
						<div className={bem('sum__result')}>
							{formatPrice(cart.products.reduce((sum, product) => sum + product.productVariant?.price! * getCartProductAmount(product), 0), locale)}
						</div>
					</div>
				</div>
			</CardContent>
			<CardActions className={bem('actions')}>
				<Button disabled={cart.products.length < 1} title={m.checkout} variant="contained" />
			</CardActions>
		</Card>
	)
}