import { useIsAuthenticated } from "@azure/msal-react"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow"
import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useEffect } from "react"

import { create } from "src/helpers/bem"

import { fetchCategories } from "src/queriesXRM/categories"
import { fetchProducts } from "src/queriesXRM/products"

import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import {
  Category,
  mergeCategories,
  useCategories,
} from "src/states/shop/category"

import { mergeFilter, useFilter } from "src/states/shop/filter"
import { mergeProducts, useProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import styles from "./ShopOverview.module.scss"
import { ShopOverviewContact } from "./ShopOverviewContact"
import { ShopOverviewInfo } from "./ShopOverviewInfo"
import { ShopOverviewLinks } from "./ShopOverviewLinks"
import { ShopOverviewNavigation } from "./ShopOverviewNavigation"
import { ShopOverviewProducts } from "./ShopOverviewProducts"
import { ShopOverviewProductsHeader } from "./ShopOverviewProductsHeader"

const bem = create(styles, "ShopOverview")

export type ShopOverviewProps = {
  messages: TranslationMessages
}

export const ShopOverview: FC<ShopOverviewProps> = ({ messages }) => {
  const locale = useLocale()
  const isAuthenticated = useIsAuthenticated()
  const { filterBy } = useFilter()
  const { loading: loadingBookings } = useBookings()
  const { loading: loadingCategories, categories } = useCategories()
  const { loading: loadingProducts } = useProducts()

  const loadProducts = async () => {
    mergeProducts({ loading: true })
    const result = await fetchProducts()
    mergeProducts({
      loading: false,
      products: result,
      filteredProducts: result,
    })
  }

  const loadCategories = async () => {
    mergeCategories({ loading: true })
    mergeCategories({ loading: false, categories: await fetchCategories() })
  }

  useEffect(() => {
    loadProducts()
    loadCategories()
  }, [locale])

  useEffect(
    () =>
      mergeFilter((f) => ({
        ...f,
        filterBy: {
          category:
            ([
              ...categories,
              ...categories.flatMap(
                (c) => c.children?.map((subCategory) => subCategory),
              ),
            ].find((c) => c?.id === f.filterBy?.category?.id) as Category) ??
            categories[0],
        },
      })),
    [categories],
  )

  if (
    (isAuthenticated && loadingBookings) ||
    loadingProducts ||
    loadingCategories
  ) {
    return <LinearProgress color="primary" />
  }

  let headlineCategory = categories[0]?.name ?? ""
  let headlineSubcategory

  if (filterBy?.category?.categoryType === "category") {
    const category = categories?.find((c) => c.id === filterBy?.category?.id)
    headlineCategory = category?.name ?? ""
  }
  if (filterBy?.category?.categoryType === "subcategory") {
    const category = categories?.find(
      (c) => c.id === filterBy?.category?.parentCategoryId,
    )
    const subcategory = category?.children?.find(
      (c) => c.id === filterBy?.category?.id,
    )
    headlineCategory = `${category?.name ?? ""}`
    headlineSubcategory = `${subcategory?.name ?? ""}`
  }

  return (
    <div className={bem()}>
      <div className={bem("headline__wrapper")}>
        <h1 className={bem("headline")}>{headlineCategory}</h1>
        {headlineSubcategory && (
          <>
            <DoubleArrowIcon
              className={bem("headline__icon")}
              fontSize="large"
            />
            <h1 className={bem("headline")}>{headlineSubcategory}</h1>
          </>
        )}
      </div>
      <div className={bem("wrapper")}>
        <div className={bem("products")}>
          <ShopOverviewProductsHeader messages={messages} />
          <ShopOverviewProducts messages={messages} />
        </div>
        <div className={bem("sidebar")}>
          <ShopOverviewNavigation messages={messages} />
          <ShopOverviewInfo messages={messages} />
          <ShopOverviewContact messages={messages} />
          <ShopOverviewLinks messages={messages} />
        </div>
      </div>
    </div>
  )
}
