import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { Category } from "./category"

export type FilterSorting =
  | "default"
  | "favorite"
  | "alphabeticUp"
  | "alphabeticDown"
  | "priceUp"
  | "priceDown"

export type FilterBy = {
  category: Category | null
}

export type Filter = {
  search: string
  filterBy?: FilterBy
  sortBy: FilterSorting
}

const { getValue, setValue, mergeValue, useValue } =
  createSimpleGlobalState<Filter>(
    {
      search: "",
      sortBy: "default",
    },
    "shop-filter",
  )

export {
  getValue as getFilter,
  setValue as setFilter,
  mergeValue as mergeFilter,
  useValue as useFilter,
}
