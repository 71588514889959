import { LinearProgress } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { Table } from "src/components/common/table"
import { create } from "src/helpers/bem"
import { getPurchaseorderPermissions } from "src/queriesXRM/purchaseorder"
import { TranslationMessages } from "src/translations"
import { xRMApiPurchaseorderPermission } from "src/types/xRM"

import styles from "./ShopStandBookingRequestsOverview.module.scss"

const bem = create(styles, "ShopStandBookingRequests")

export type ShopStandBookingRequestsOverviewProps = {
	messages: TranslationMessages
}

export const ShopStandBookingRequestsOverview: FC<ShopStandBookingRequestsOverviewProps> = ({ messages }) => {
	const [loading, setLoading] = useState(false)
	const [standBookingRequests, setStandBookingRequests] = useState<xRMApiPurchaseorderPermission[]>([])
	const m = messages.pages.shop.standBookingRequestsOverview

	useEffect(() => {
		setLoading(true)
		const fetchStandBookingRequests = async () => {
			setStandBookingRequests(await getPurchaseorderPermissions())
		}
		fetchStandBookingRequests().finally(() => setLoading(false))
	}, [])

	const formattedStandBookingRequests = standBookingRequests.map(request => ({
		name: request.salesorder!.name!,
		standhall: request.standHall!,
		standnumber: request.standName!,
		standsize: request.standSize!,
		state: request.state
	}))

	if (loading) return <LinearProgress color="primary" />

	return (
		<div className={bem()}>
		{formattedStandBookingRequests.length > 0 ? (
			<div className={bem("table")}>
				<Table
					cellTranslations={m.table}
					data={formattedStandBookingRequests}
					messages={messages}
					order="desc"
					orderBy="state"
				/>
			</div>
		) : (
			<p className={bem("description")}>
				{m.noData}
			</p>
		)}
		</div>
	)
}
