import { LinearProgress } from "@mui/material"
import { useRouter } from "next/router"
import React, { FC, useEffect, useState } from "react"

import { create } from "src/helpers/bem"

import { getUrlParameter } from "src/helpers/urlParams"
import { fetchProducts } from "src/queriesXRM/products"
import { getProductVariants } from "src/queriesXRM/productVariants"
import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"
import { mergeProducts, useProducts } from "src/states/shop/products"

import {
  mergeProductVariants,
  useProductVariants,
} from "src/states/shop/productVariants"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopDetail.module.scss"
import { ShopDetailContent } from "./ShopDetailContent"
import { ShopDetailHeader } from "./ShopDetailHeader"

const bem = create(styles, "ShopDetail")

export type ShopDetailProps = {
  pages: PagesProps
  messages: TranslationMessages
}

export const ShopDetail: FC<ShopDetailProps> = ({ pages, messages }) => {
  const locale = useLocale()
  const router = useRouter()
  const m = messages?.pages.shop.detail.snackbar
  const { products, loading: loadingProducts } = useProducts()
  const { variants, loading: loadingProductVariants } = useProductVariants()
  const [productVariant, setProductVariant] = useState(variants[0])

  const product = products.find((p) => p.id === getUrlParameter("id"))

  const loadProducts = async () => {
    mergeProducts({ loading: true })
    const result = await fetchProducts()
    mergeProducts({
      loading: false,
      products: result,
      filteredProducts: result,
    })
  }

  const loadProductVariants = async () => {
    if (!product) {
      const shopOverviewURL = pages?.data.nodes.find(
        (p) =>
          p.template?.templateName === "Webshop Overview Template" &&
          p.language?.slug === locale,
      )?.uri
      if (shopOverviewURL) {
        addNotification({
          autoclose: true,
          variant: "warning",
          content: `${m?.notFound}`,
        })
        router.push(shopOverviewURL)
      }
      return
    }
    mergeProductVariants({ loading: true })
    const loadedVariants = await getProductVariants(product!)
    setProductVariant(loadedVariants[0])
    mergeProductVariants({
      loading: false,
      variants: loadedVariants,
    })
  }

  useEffect(() => {
    loadProducts()
  }, [locale])

  useEffect(() => {
    if (!loadingProducts) {
      loadProductVariants()
    }
  }, [locale, loadingProducts, messages])

  if (loadingProducts || loadingProductVariants) {
    return <LinearProgress color="primary" />
  }

  return (
    <div className={bem()}>
      <div>
        <ShopDetailHeader
          messages={messages}
          pages={pages}
          product={product!}
          setVariant={setProductVariant}
          variant={productVariant}
        />
      </div>
      <div>
        <ShopDetailContent product={product!} variant={productVariant} />
      </div>
    </div>
  )
}
