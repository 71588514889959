import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, MouseEvent, useEffect } from "react"

import { Table, TableData } from "src/components/common/table"
import { create } from "src/helpers/bem"

import { loadBookings } from "src/queriesXRM/salesorder"
import { mergeBooking } from "src/states/bookingDetail"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"

import { SharedPageProps } from "src/types/SharedPageProps"

import styles from "./BookingOverview.module.scss"

const bem = create(styles, "BookingOverview")

export const BookingOverview: FC<SharedPageProps> = ({ messages }) => {
  const router = useRouter()
  const locale = useLocale()
  const { loading: loadingBookings, bookings } = useBookings()

  useEffect(() => {
    loadBookings(locale)
  }, [locale])

  const handleSearchTable = (value: string, data: TableData[]) => {
    const Value = value.toLowerCase()
    return data.filter((item) => {
      if (!Value) return true
      if (
        item.name?.toLowerCase()?.includes(Value) ||
        (item.exhibition as string)?.toLowerCase()?.includes(Value) ||
        (item.exhibitor as string)?.toLowerCase()?.includes(Value) ||
        (typeof item?.client === "string" &&
          item?.client?.toLowerCase()?.includes(Value))
      ) {
        return true
      }
    })
  }

  const handleClickTableCell = (e: MouseEvent<unknown>, name: string) => {
    const bookingId = bookings.filter((el) => el.name === name)?.[0]?.id
    mergeBooking({
      id: bookingId?.toString() ?? "",
    })
    let bookingDetailURL = "/en/booking-details"
    switch (locale) {
      case "de":
        bookingDetailURL = "/de/buchungsdetails"
        break
      default:
        bookingDetailURL = "/en/booking-details"
        break
    }
    router.push(bookingDetailURL)
  }

  if (loadingBookings) {
    return <LinearProgress color="primary" />
  }

  return (
    <div className={bem()}>
      {bookings && bookings.length > 0 ? (
        <div className={bem("table")}>
          <Table
            cellTranslations={messages.pages.booking.overview.table}
            data={bookings}
            handleClickCell={handleClickTableCell}
            handleSearch={handleSearchTable}
            messages={messages}
            order="desc"
            orderBy="name"
          />
        </div>
      ) : (
        <p className={bem("description")}>
          {messages.pages.booking.overview.noData}
        </p>
      )}
    </div>
  )
}
