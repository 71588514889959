import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, useEffect, useState } from "react"

import {
  AdvancedForm,
  AdvancedFormStepProps,
} from "src/components/common/advancedForm"

import { formatFormData } from "src/helpers/form"
import { getFilesById } from "src/queriesXRM/file"
import { getForm, postForm } from "src/queriesXRM/form"
import { mergeBooking } from "src/states/bookingDetail"
import { useLocale } from "src/states/locale"
import {
  setSalesorderActiveStep,
  mergeSalesorderField,
  setSalesorderSteps,
  useSalesorder,
  getSalesorder,
  mergeSalesorder,
  resetSalesorder,
  mergeSalesorderStepById,
  mergeSalesorderFormById,
  mergeSalesorderFieldById,
} from "src/states/salesorder"

import { TranslationMessages } from "src/translations"
import {
  xRMApiStep,
  xRMApiField,
  xRMApiSalesorderPhase,
  xRMApiFile,
} from "src/types/xRM"

export type MainExhibitorBookingProps = {
  messages: TranslationMessages
}

export const MainExhibitorBooking: FC<MainExhibitorBookingProps> = ({
  messages,
}) => {
  const [loading, setLoading] = useState(false)
  const formTranslations = messages.pages.standBooking
  const router = useRouter()
  const locale = useLocale()
  const { salesorderId, activeStep, steps } = useSalesorder()

  const loadForm = async () => {
    const data = await getSalesorder()
    setLoading(true)
    const form = await getForm("Main")

    if (data.steps.length !== 0) {
      form.map((step: xRMApiStep) => {
        mergeSalesorderStepById(
          {
            id: step.id,
            position: step.position,
            label: step.label,
            dependencies: step.dependencies,
          },
          step.id,
        )
        return step?.forms?.map((form) => {
          mergeSalesorderFormById(
            {
              id: form.id,
              position: form.position,
              label: form.label,
              helperText: form.helperText,
              fields: form.fields,
            },
            step.id,
            form.id,
          )
          return form?.fields?.map(async (field) => {
            let Field = field
            delete Field.conditions
            delete Field.value
            if (Field.type === "file") {
              const defaultFiles = await getFilesById(salesorderId)
              const filteredDefaultFiles = defaultFiles?.filter(
                (f: xRMApiFile) => f.formControl === "",
              ) // Before filtered name of field
              Field = {
                ...Field,
                files: filteredDefaultFiles,
              } as xRMApiField
            }
            return mergeSalesorderFieldById(
              {
                ...Field,
              },
              step.id,
              form.id,
              field.id,
            )
          })
        })
      })
    } else {
      setSalesorderSteps(form)
    }
    setLoading(false)
  }

  useEffect(() => {
    setSalesorderActiveStep(0)
  }, [])

  useEffect(() => {
    loadForm()
  }, [locale])

  const onSendForm = async (phase?: xRMApiSalesorderPhase) => {
    setLoading(true)
    const data = await formatFormData(
      "Main",
      activeStep,
      steps as ReadonlyArray<xRMApiStep>,
      undefined,
      salesorderId,
      phase,
    )
    let redirectURL = ""
    switch (locale) {
      case "de":
        redirectURL = "/de/buchungsdetails"
        break
      default:
        redirectURL = "/en/booking-details"
        break
    }
    return postForm(data, phase)
      .then((salesorder) => {
        setLoading(false)
        if (phase === "Draft") {
          mergeSalesorder({
            salesorderId: salesorder.id,
          })
          return
        }
        mergeBooking({
          id: salesorder.id,
        })
        router.push(redirectURL)
        resetSalesorder()
        loadForm()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }

  return (
    <AdvancedForm
      stepLabelsClickable
      activeStep={activeStep}
      data={steps as AdvancedFormStepProps[]}
      formMessages={formTranslations}
      handleChangeActiveStep={setSalesorderActiveStep}
      handleMergeField={mergeSalesorderField}
      id={salesorderId}
      messages={messages}
      buttons={{
        backButtonLabel: formTranslations.buttons.back,
        nextButtonLabel: formTranslations.buttons.next,
        draftButtonLabel: formTranslations.buttons.draft,
        finishButtonLabel: formTranslations.buttons.finish,
      }}
      onSendForm={onSendForm}
    />
  )
}
