import { useAccount, useMsal } from "@azure/msal-react"
import React, { FC } from "react"

import { resetPasswordRequest } from "src/auth/msalConfig"
import { Button } from "src/components/common/button"

import { create } from "src/helpers/bem"
import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"

import { TranslationMessages } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import styles from "./AccountOverviewPrivate.module.scss"

const bem = create(styles, "AccountOverviewPrivate")

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type AccountOverviewPrivateProps = Omit<SharedPageProps, "messages"> & {
  messages: TranslationMessages | null
}

export const AccountOverviewPrivate: FC<AccountOverviewPrivateProps> = ({
  messages,
  support,
}) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])
  const m = messages?.pages.account.overview.account
  const locale = useLocale()

  const resetPassword = () => {
    instance
      .loginRedirect({
        ...resetPasswordRequest,
        extraQueryParameters: { lang: locale },
      })
      .catch((e) => {
        addNotification({
          variant: "error",
        })
      })
  }

  const renderAccountDataItem = (title: string, value?: string) => (
    <li className={bem("item")}>
      <span className={bem("item__title")}>{title}</span>
      <span>{value && value !== "unknown" ? value : m?.userInfo.noData}</span>
    </li>
  )

  return (
    <div className={bem()}>
      <p className={bem("content")}>{m?.desc}</p>
      <ul className={bem("items")}>
        {renderAccountDataItem(m?.userInfo.name ?? "", account?.name)}
        {renderAccountDataItem(
          m?.userInfo.email ?? "",
          account?.idTokenClaims?.email as string,
        )}
      </ul>
      <div className={bem("action")}>
        <p className={bem("action__desc")}>{m?.reset.desc}</p>
        <Button
          className={bem("action__button")}
          title={m?.reset.button}
          onClick={() => resetPassword()}
        />
      </div>
      {support.data.themeOptions?.support?.email?.account ? (
        <div className={bem("action")}>
          <p className={bem("action__desc")}>
            {m?.support.desc1}&nbsp;
            {support.data.themeOptions?.support?.email.account}
            {m?.support.desc2}
          </p>
          <Button
            className={bem("action__button")}
            title={m?.support.button}
            onClick={(e) => {
              window.location.href = `mailto:${support.data.themeOptions?.support?.email?.account}`
              e.preventDefault()
            }}
          />
        </div>
      ) : null}
    </div>
  )
}
