import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LinearProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo } from "react"
import { Button } from "src/components/common/button"
import { create } from 'src/helpers/bem';
import { deepClone } from 'src/helpers/deepClone';
import { postPurchaseorderRefresh } from 'src/queriesXRM/purchaseorder';
import { useLocale } from 'src/states/locale';
import { CartProduct, mergeCarts, setCart, useCart, useCarts } from 'src/states/shop/carts';
import { TranslationMessages } from "src/translations"

import { PagesProps } from 'src/types/SharedPageProps';

import { ShopCartProductCard } from '../ShopCartProductCard';

import styles from "./ShopCartOverview.module.scss"
import { ShopCartOverviewSummary } from './ShopCartOverviewSummary';


const bem = create(styles, "ShopCartOverview")

export type ShopCartOverviewProps = {
	messages: TranslationMessages,
	pages: PagesProps
}

export const ShopCartOverview: FC<ShopCartOverviewProps> = ({ messages, pages }) => {
	const locale = useLocale()
	const router = useRouter()
	const cart = useCart()
	const { loading } = useCarts()
	const m = messages.pages.shop.cart.overview

	const webshopOverviewPage = useMemo(() =>
      pages?.data?.nodes?.filter(page =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Webshop Overview Template",
      )?.[0],
	  [pages, locale]
	)

	const refreshCart = async () => {
		if (cart.products.length === 0) return
		mergeCarts({ loading: true })
		const refreshed = await postPurchaseorderRefresh({
			salesorderId: cart.orderId,
			parentSalesorderId: cart.id,
			exhibitionId: process.env.NEXT_PUBLIC_XRM_EVENT_ID,
			products: deepClone<CartProduct[]>(cart.products),
		})
		setCart(refreshed.products ?? [])
		mergeCarts({ loading: false })
	}

	useEffect(() => {
		refreshCart()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (loading) return <LinearProgress />

	return (
		<div className={bem()}>
			<Button
				startIcon={<ArrowBackIcon />}
				title={m.backToShop}
				variant="text"
				onClick={async () => await router.push(webshopOverviewPage?.uri as string)}
			/>
			<div className={bem('container')}>
				<div className={bem('items')}>
					{cart.products.length > 0
						? cart.products.map(product =>
							<ShopCartProductCard
								key={product.productVariant?.id}
								item={product}
							/>)
						: <div className={bem('noData')}>{m.noData}</div>}
				</div>
				<div className={bem('summary')}>
					<ShopCartOverviewSummary />
				</div>
			</div>
		</div>
	)
}
