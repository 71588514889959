import React, { FC } from "react"

import { create } from "src/helpers/bem"
import { TranslationMessages } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import styles from "./AccountOverviewOrganization.module.scss"

const bem = create(styles, "AccountOverviewOrganization")

export type AccountOverviewWebshopProps = Omit<SharedPageProps, "messages"> & {
  messages: TranslationMessages | null
}

export const AccountOverviewWebshop: FC<AccountOverviewWebshopProps> = ({ messages }) => {
  const m = messages?.pages.account.overview.webshop
  return (
    <div className={bem()}>
      {m?.description}
    </div>
  )
}
