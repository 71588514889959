import { EventType, EventMessage, InteractionStatus } from "@azure/msal-browser"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import LinearProgress from "@mui/material/LinearProgress"
import DOMPurify from "isomorphic-dompurify"
import moment from "moment"
import { useRouter } from "next/router"
import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from "react"
import "moment/locale/de"

// COMMON
import { Card } from "src/components/common/card"
import { Breadcrumb } from "src/components/core/breadcrumb"
import {
  BasicLayout,
  StaticContactProps,
  DialogTypeProps,
} from "src/components/layouts/basic"

// SERVICES
import { AccountOverview } from "src/components/services/account/overview"
import { BookingDetail } from "src/components/services/booking/detail"
import { BookingOverview } from "src/components/services/booking/overview"
import { CoExhibitorBookingDetail } from "src/components/services/coExhibitorBooking/detail"
import { CoExhibitorBookingForm } from "src/components/services/coExhibitorBooking/form"
import { MainExhibitorBooking } from "src/components/services/mainExhibitorBooking"
import { ShopOverview } from "src/components/services/shop/overview"

// HELPERS
import { create } from "src/helpers/bem"
import { fetchXRM } from "src/helpers/fetchQueriesXRM"
import { resetStates } from "src/helpers/reset"

// STATES
import { getInvitationAddressBySalesorderId } from "src/queriesXRM/invite"
import { useAccount, getAccount, isAccountIncomplete } from "src/states/account"
import { useAccountForm } from "src/states/accountForm"
import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"

import { SharedPageProps } from "src/types/SharedPageProps"

import { ShopCartOverview } from "../services/shop/cart/overview"
import { ShopDetail } from "../services/shop/detail"

import { ShopStandBookingRequestsOverview } from "../services/shop/stand-booking-requests-overview"

import styles from "./ContentPage.module.scss"

const bem = create(styles, "ContentPage")

export const ContentPage: FC<SharedPageProps> = (props) => {
  const { messages, page } = props
  const sidebarMessages = messages?.pages?.sidebar
  const [loading, setLoading] = useState(true)
  const [staticContact, setStaticContact] = useState("")
  const redirect = page?.data?.redirect
  const template = page?.data?.template?.templateName
  const router = useRouter()
  const locale = useLocale()
  const account = useAccount()
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const redirectRegistration =
    router?.asPath === `/de/registrierung/` ||
    router?.asPath === `/en/registration/`
  useAccountForm()

  const validateAuthentication = useCallback(async () => {
    setLoading(true)
    let account = getAccount()
    if (redirectRegistration && isAuthenticated) {
      await router.push("/")
      setLoading(false)
      return
    }

    // in rare cases is the account not filled. Try to fetch data before redirecting
    if (
      redirect?.profileCompleted &&
      isAccountIncomplete() &&
      isAuthenticated
    ) {
      await fetchXRM(locale)
      account = getAccount()
    }

    let redirectURL
    if (
      redirect?.loggedIn &&
      isAccountIncomplete() &&
      !isAuthenticated
    ) {
      switch (locale) {
        case "de":
          redirectURL = "/de/registrierung"
          break
        default:
          redirectURL = "/en/registration"
          break
      }
      await router.push(redirectURL)
      setLoading(false)
      return addNotification({
        variant: "warning",
        code: "not/loggedIn",
        autoclose: true,
      })
    }
    if (redirect?.profileCompleted && isAccountIncomplete()) {
      let accountURL = "/en/account"
      switch (locale) {
        case "de":
          accountURL = "/de/konto"
          break
        default:
          accountURL = "/en/account"
          break
      }
      await router.push(accountURL)
      setLoading(false)
      return addNotification({
        variant: "warning",
        code: "account/info/missing",
        autoclose: true,
      })
    }
    setLoading(false)
    return
  }, [
    account,
    isAuthenticated,
    locale,
    redirect?.loggedIn,
    redirect?.profileCompleted,
    router,
    router?.asPath,
  ])

  const getMainExhibitorAccount = async () => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    const salesorderId = params.get("invitationcode") as string
    if (salesorderId) {
      const account = await getInvitationAddressBySalesorderId(salesorderId)
      if (account) {
        setStaticContact(`
          <p>
            ${account?.clientAddressAccount1}<br>
            ${account?.clientAddressStreet1}<br>
            ${account?.clientAddressZipCode} ${account?.clientAddressCity}<br>
            ${account?.clientAddressCountry}<br>
          </p>
        `)
      }
    }
  }

  useEffect(() => {
    if (
      isAuthenticated &&
      (template === "Co-Exhibitor Booking Form Template" ||
        template === "Co-Exhibitor Booking View Template")
    ) {
      getMainExhibitorAccount()
    }
  }, [isAuthenticated, locale, router.asPath])

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      validateAuthentication()
    }
  }, [isAuthenticated, router.asPath, inProgress])

  useEffect(() => {
    moment.locale(locale)
    if (isAuthenticated) {
      fetchXRM(locale)
    }
  }, [locale])

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGOUT_START) {
        resetStates()
      }
      if (
        message.eventType === EventType.LOGIN_SUCCESS ||
        message.eventType === EventType.ACCOUNT_ADDED
      ) {
        fetchXRM(locale)
      }
    })
    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [])

  if (loading) {
    return <LinearProgress color="primary" />
  }

  const renderLayout = (
    children: ReactElement,
    noHeadline?: boolean,
    staticContact?: StaticContactProps,
    loadDialogs?: DialogTypeProps[],
    bgColor?: "primary" | "secondary",
  ) => (
    <BasicLayout
      {...props}
      bgColor={bgColor}
      loadDialogs={loadDialogs}
      staticContact={staticContact}
    >
      <Breadcrumb items={page?.data?.meta?.breadcrumb} />
      {page?.data?.sidebar?.info?.content ? (
        <Card>
          <div className={bem("card__content")}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(page.data.sidebar.info.content),
              }}
            />
          </div>
        </Card>
      ) : null}
      {!noHeadline ? <h1>{page?.data?.title}</h1> : null}
      {children}
    </BasicLayout>
  )

  const sanitizedContent = DOMPurify.sanitize(props?.page?.data?.content ?? "")
  switch (template) {
    case "Account Overview Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <AccountOverview {...props} />
        </>,
      )
    case "Booking Overview Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <BookingOverview {...props} />
        </>,
      )
    case "Booking Detail Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <BookingDetail {...props} />
        </>,
        true,
        undefined,
        [
          "AddCoExhibitor",
          "EditCoExhibitor",
          "DeleteCoExhibitor",
          "Permission",
          "DeleteFile",
          "RejectPlacement",
        ],
      )
    case "Booking Form Template":
      return renderLayout(<MainExhibitorBooking {...props} />)
    case "Co-Exhibitor Booking Form Template":
      return renderLayout(
        <CoExhibitorBookingForm {...props} />,
        undefined,
        staticContact
          ? {
              title: sidebarMessages?.staticContact?.title,
              content: staticContact,
            }
          : undefined,
      )
    case "Co-Exhibitor Booking View Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <CoExhibitorBookingDetail {...props} />
        </>,
        undefined,
        staticContact
          ? {
              title: sidebarMessages?.staticContact?.title,
              content: staticContact,
            }
          : undefined,
      )
    case "Webshop Overview Template":
      return renderLayout(
        <ShopOverview {...props} />,
        true,
        undefined,
        ["ShopOverviewStandSelection", "ShopOverviewStandRequest", "ShopCart"],
        "primary",
      )
    case "Webshop Product Detail Template":
      return renderLayout(
        <ShopDetail {...props} />,
        true,
        undefined,
        ["ShopDetailProductFeature", "ShopCart"],
        "primary",
      )
    case "Webshop Standrequests Overview Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <ShopStandBookingRequestsOverview {...props} />
        </>,
        false,
        undefined,
        undefined,
        "primary",
      )
    case "Webshop Cart Overview Template":
      return renderLayout(
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
          <ShopCartOverview {...props} />
        </>,
        false,
        undefined,
        undefined,
        "primary",
      )
    default:
      return renderLayout(
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />,
        undefined,
        undefined,
      )
  }
}
