import { SelectChangeEvent } from "@mui/material/Select"
import React, { FC, ChangeEvent, useEffect, useMemo, useCallback } from "react"

import { Accordion } from "src/components/common/accordion"
import { InputField } from "src/components/common/inputfield"
import { Select } from "src/components/common/select"

import { create } from "src/helpers/bem"
import { Category } from "src/states/shop/category"
import { useFilter, mergeFilter, FilterSorting } from "src/states/shop/filter"
import { useProducts, mergeProducts } from "src/states/shop/products"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewProductsHeader.module.scss"

const bem = create(styles, "ShopOverviewProductsHeader")

export type ShopOverviewProductsHeaderProps = {
  messages: TranslationMessages
}

export const productsSortingOptions = [
  "default",
  "favorite",
  "alphabeticUp",
  "alphabeticDown",
  "priceUp",
  "priceDown",
] as FilterSorting[]

export const ShopOverviewProductsHeader: FC<
  ShopOverviewProductsHeaderProps
> = ({ messages }) => {
  const m = messages.pages.shop.overview.header
  const { products } = useProducts()
  const { filterBy, search, sortBy } = useFilter()

  const isAllProductsCategory = (category: Category) =>
    category.categoryType === "category" && category.children === undefined

  const onFilter = useCallback(async () => {
    let result = [...products]

    if (
      filterBy?.category?.id &&
      !isAllProductsCategory(filterBy.category as Category)
    ) {
      result = result.filter((p) =>
        [p.categoryId, p.parentCategoryId].includes(filterBy.category?.id),
      )
    }

    result = result.filter(
      (p) =>
        p.name?.toLowerCase().includes(search.toLowerCase()) ||
        p.note?.toLowerCase().includes(search.toLowerCase()),
    )

    switch (sortBy) {
      case "alphabeticUp":
        result.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
        break
      case "alphabeticDown":
        result.sort((a, b) => (b.name ?? "").localeCompare(a.name ?? ""))
        break
      case "priceUp":
        result.sort((a, b) => (a.price ?? 0) - (b.price ?? 0))
        break
      case "priceDown":
        result.sort((a, b) => (b.price ?? 0) - (a.price ?? 0))
        break
      case "favorite":
        result.sort((a, _) => (a.favorite ? -1 : 1))
        break
    }

    mergeProducts({ filteredProducts: result })
  }, [filterBy, products, search, sortBy])

  useEffect(() => {
    onFilter()
  }, [filterBy, sortBy, search, onFilter])

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    mergeFilter({
      search: e?.target?.value ?? "",
    })
  }

  const handleChangeSorting = (e: SelectChangeEvent<unknown>) => {
    const value = e?.target?.value as FilterSorting
    mergeFilter({
      sortBy: value,
    })
  }

  const sortingItems = useMemo(
    () =>
      productsSortingOptions.map((key) => ({
        key,
        value: m.sorting.items?.[key],
      })),
    [m.sorting.items],
  )
  return (
    <div className={bem()}>
      {filterBy?.category?.notice && (
        <div className={bem("content__wrapper")}>
          <Accordion
            id="info"
            label={m.info.title}
            classNames={{
              label: bem("accordion__label"),
            }}
          >
            <div className={bem("info")}>
              {filterBy?.category?.notice?.title && (
                <span className={bem("info__title")}>
                  {filterBy?.category?.notice.title}
                </span>
              )}
              {filterBy?.category?.notice?.content && (
                <span className={bem("info__content")}>
                  {filterBy?.category?.notice.content}
                </span>
              )}
            </div>
          </Accordion>
        </div>
      )}
      <div className={bem("toolbar")}>
        <InputField
          label={m.search.input}
          name="search"
          type="text"
          value={search}
          classes={{
            root: bem("input"),
          }}
          onChange={handleChangeSearch}
        />
        <Select
          items={sortingItems}
          label={m.sorting.input}
          messages={messages}
          name="sorting"
          value={sortBy}
          classes={{
            selectedItem: bem("input"),
          }}
          onChange={handleChangeSorting}
        />
      </div>
    </div>
  )
}
